import React, { useEffect, useState } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
// import Moment from "react-moment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Profile from "Assets/user.svg";
import EditUser from "Components/Verified/ediUser";
import Modal from "./modal";
import Verify from "./Verify";
function User() {
    const Navigate = useNavigate();
    const { id } = useParams();
    const [params] = useSearchParams();
    const [modal, setModal] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);

    const [img, setimg] = useState("");
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const getusers = (offset) => {
        Services.UserDetailsById("GET", null, token, id)
            .then((Response) => {
                console.log(Response);
                // offset

                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.UserDetails);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);
        // eslint-disable-next-line
    }, []);
    const userHandeler = (e) => {
        let type = e.currentTarget.getAttribute("btntype");
        let value = e.currentTarget.getAttribute("value");
        let body;
        if (type === "payout") {
            body = { isPayoutBlocked: value };
        } else if (type == "block") {
            body = {
                isBlocked: value,
            };
        } else if (type == "kyc") {
            body = {
                isKYCverified: 1,
            };
        } else if (type === "instantPayoutsOn") {
            body = { instantPayoutsOn: data.instantPayoutsOn === 1 ? 0 : 1 };
        }
        Services.kycUpdate("PUT", JSON.stringify(body), token, id)
            .then((Response) => {
                getusers(0);
                // offset
                if (Response.Status === 1) {
                    toast.success(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const verifyhandler = (e) => {
        let btn = e.currentTarget.getAttribute("btn-type");
        let body = { [btn]: 1 };
        Services.kycUpdate("PUT", JSON.stringify(body), token, id)
            .then((Response) => {
                getusers(0);
                // offset
                if (Response.Status === 1) {
                    toast.success(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const handleDownload = (imageUrl) => {
        const anchor = document.createElement("a");
        anchor.href = imageUrl;
        anchor.download = "image.jpg";
        anchor.click();
    };

    const resetKYC = () => {
        if (window.confirm("Are you sure you want to reset KYC ?")) {
            Services.resetKYC("PUT", JSON.stringify({}), token, id)
                .then((Response) => {
                    getusers(0);
                    // offset
                    if (Response.Status === 1) {
                        toast.success(`${Response.Message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                    } else {
                        toast.error(`${Response.Message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    alert("something went wrong please try later");
                    console.log(err);
                });
        }
    };
    return (
        <>
            {verifyModal && (
                <Verify setmodal={setVerifyModal} id={id} data={data} />
            )}
            {open && (
                <Modal
                    onOpenModal={onOpenModal}
                    onCloseModal={onCloseModal}
                    open={open}
                    img={img}
                />
            )}
            <Layout isactive={params.get("type") ? "approval" : "verified"}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={tableClasses["table"]}>
                        {modal && (
                            <EditUser
                                modal={modal}
                                data={data}
                                setmodal={setModal}
                                walletCommissionPercentage={
                                    data.walletCommissionPercentage
                                }
                                dailyLimit={data?.dailyLimit}
                                isPayBillsEnabled={data?.isPayBillsEnabled}
                                payoutChargeAmount={data.payoutChargeAmount}
                                referredByCode={data.referredByCode}
                                referredbyUserPhoneNumber={
                                    data.referredbyUserPhoneNumber
                                }
                                referralCommissionPercentage={
                                    data.referralCommissionPercentage
                                }
                                monthlyLimit={data.monthlyLimit}
                                id={id}
                                getusers={getusers}
                            />
                        )}
                        <button
                            onClick={() => {
                                Navigate(-1);
                            }}
                            style={{
                                margin: "1rem 0",
                                justifySelf: "left",
                                backgroundColor: "red",
                            }}>
                            Back
                        </button>
                        <div className={tableClasses["block-btns"]}>
                            <h3 className={tableClasses["heading"]}>
                                Users Details
                            </h3>
                            <button
                                onClick={userHandeler}
                                btntype="payout"
                                value={data?.isPayoutBlocked === 1 ? 0 : 1}
                                className={
                                    data?.isPayoutBlocked === 1
                                        ? tableClasses["green-btn"]
                                        : tableClasses["red-btn"]
                                }>
                                {data?.isPayoutBlocked === 1
                                    ? "Enable Payout"
                                    : "Block Payout"}
                            </button>
                            <button
                                onClick={userHandeler}
                                btntype="block"
                                value={data?.isBlocked === 1 ? 0 : 1}
                                className={
                                    data?.isBlocked === 1
                                        ? tableClasses["green-btn"]
                                        : tableClasses["red-btn"]
                                }>
                                {data?.isBlocked === 1
                                    ? "Enable User"
                                    : "Block User"}
                            </button>
                            <button
                                onClick={() => {
                                    Navigate(`/payoutContacts/${id}`);
                                }}
                                className={tableClasses["green-btn"]}>
                                Payout Contacts
                            </button>
                            {data?.isKYCverified == 2 && (
                                <button
                                    className={tableClasses["green-btn"]}
                                    onClick={userHandeler}
                                    btntype="kyc">
                                    Approve Kyc
                                </button>
                            )}
                            <button
                                onClick={() => {
                                    Navigate(`/ReferralUsers/${id}`);
                                }}
                                className={tableClasses["green-btn"]}>
                                Referral Users
                            </button>
                            <button
                                onClick={resetKYC}
                                btntype="payout"
                                value={data?.isPayoutBlocked === 1 ? 0 : 1}
                                className={tableClasses["red-btn"]}>
                                Reset KYC
                            </button>
                            <button
                                onClick={userHandeler}
                                btntype="instantPayoutsOn"
                                className={
                                    data.instantPayoutsOn == 1
                                        ? tableClasses["red-btn"]
                                        : tableClasses["green-btn"]
                                }>
                                Instant Payout{" "}
                                {data.instantPayoutsOn == 1 ? "Off" : "ON"}
                            </button>
                        </div>

                        <div className={tableClasses["user-details"]}>
                            <button
                                style={{ marginTop: "0.6rem" }}
                                onClick={() => setModal(true)}>
                                Edit
                            </button>
                            {data.profilePic === "NA" ? (
                                <img src={Profile} alt="profilepic" />
                            ) : (
                                <img src={data.profilePic} alt="profilepic" />
                            )}

                            <div className={tableClasses["user-data"]}>
                                <h3>First name</h3>
                                <p>:</p>
                                <p>{data.firstName}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Last name</h3>
                                <p>:</p>
                                <p>{data.lastName}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Email</h3>
                                <p>:</p>
                                <p>{data.emailId}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Address</h3>
                                <p>:</p>
                                <p>{data.address}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Mobile number</h3>
                                <p>:</p>
                                <p>{data.mobileNumber}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>DOB</h3>
                                <p>:</p>
                                <p>{data.DOB}</p>
                            </div>

                            <div className={tableClasses["user-data"]}>
                                <h3>PAN</h3>
                                <p>:</p>
                                <p>{data.PAN}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Aadhar</h3>
                                <p>:</p>
                                <p>{data.aadharNumber}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>KYC Holder Account No</h3>
                                <p>:</p>
                                <p>
                                    {data.KYCHolderAccountNo}{" "}
                                    <button
                                        onClick={() => {
                                            setVerifyModal(true);
                                        }}>
                                        Verify
                                    </button>
                                </p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>KYC Holder Account IFSC</h3>
                                <p>:</p>
                                <p>{data.KYCHolderAccountIFSC}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>wallet Amount</h3>
                                <p>:</p>
                                <p>{data.walletAmount} rs</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>payment PageId</h3>
                                <p>:</p>
                                <p>{data.paymentPageId}</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Payoutblocked</h3>
                                <p>:</p>
                                <p>
                                    {data.isPayoutBlocked === 1 ? "YES" : "NO"}
                                </p>
                            </div>

                            <div className={tableClasses["user-data"]}>
                                <h3>wallet commission percentage</h3>
                                <p>:</p>
                                <p>{data.walletCommissionPercentage}%</p>
                            </div>
                            <div className={tableClasses["user-data"]}>
                                <h3>Daily Limit</h3>
                                <p>:</p>
                                <p>{data.dailyLimit}</p>
                            </div>
                            <div
                                className={`${tableClasses["user-data"]} ${tableClasses["user-data-img"]}`}>
                                <h3>PAN Image</h3>
                                <p>:</p>
                                <div>
                                    {data.PANImage != "NA" ? (
                                        <div>
                                            <img
                                                src={data.PANImage}
                                                alt="user"
                                                onClick={(e) => {
                                                    setimg(
                                                        e.currentTarget.getAttribute(
                                                            "src"
                                                        )
                                                    );
                                                    setOpen(true);
                                                }}
                                            />
                                            <button
                                                onClick={() => {
                                                    handleDownload(
                                                        data.PANImage
                                                    );
                                                }}>
                                                Save
                                            </button>
                                        </div>
                                    ) : (
                                        <p>NA</p>
                                    )}
                                    {data.isPANImage_Verified == 0 && (
                                        <button
                                            btn-type="isPANImage_Verified"
                                            onClick={verifyhandler}
                                            disabled={data.PANImage == "NA"}>
                                            Verify
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${tableClasses["user-data"]} ${tableClasses["user-data-img"]}`}>
                                <h3>User Selfie</h3>
                                <p>:</p>
                                <div>
                                    {data.userSelfieURL != "NA" ? (
                                        <div>
                                            <img
                                                src={data.userSelfieURL}
                                                alt="user"
                                                onClick={(e) => {
                                                    setimg(
                                                        e.currentTarget.getAttribute(
                                                            "src"
                                                        )
                                                    );
                                                    setOpen(true);
                                                }}
                                            />
                                            <button
                                                onClick={() => {
                                                    handleDownload(
                                                        data.userSelfieURL
                                                    );
                                                }}>
                                                Save
                                            </button>
                                        </div>
                                    ) : (
                                        <p>NA</p>
                                    )}
                                    {data.isuserSelfie_Verified == 0 && (
                                        <button
                                            btn-type="isuserSelfie_Verified"
                                            onClick={verifyhandler}
                                            disabled={
                                                data.userSelfieURL == "NA"
                                            }>
                                            Verify
                                        </button>
                                    )}
                                </div>
                            </div>
                            {/* <div className={tableClasses["user-data"]}>
                                <h3>wallet commission percentage</h3>
                                <p>:</p>
                                <p>{data.referredbyUserPhoneNumber}%</p>
                            </div> */}
                        </div>
                    </div>
                )}
            </Layout>
        </>
    );
}

export default User;
