import React, { useEffect, useState, useRef } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import TransactionDetails from "./transactionDetails";
import moment from "moment";
import Moment from "react-moment";
function User() {
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState("");
    const fromref = useRef();
    const toref = useRef();
    const searchref = useRef();
    const [data, setData] = useState([]);

    const [dates, setDates] = useState({});
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let firstTime;
    const [prevoffset, setPrevOffset] = useState();
    const [next, setNext] = useState(true);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [max, setMax] = useState();
    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);
    const getusers = (offset) => {
        let body = JSON.stringify({
            ...dates,
        });
        Services.bbpsTransactions("POST", body, token, offset)
            .then((Response) => {
                setLoading(false);
                console.log(Response);
                // offset
                if (Response.Status === 1) {
                    setData(Response.BBPSTransactions);
                    if (firstTime) {
                        firstTime = false;
                        setMax(Response.Offset);
                    }

                    if (Response.Offset !== -1) {
                        setoffset(Response.Offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);
        // eslint-disable-next-line
    }, []);
    const row = {
        onClick: (e, row, rowIndex) => {
            setModel(true);
            setTransactionDetails(row);
        },
    };
    const StatusFormatter = (cell) => {
        if (cell === 1) {
            return <p style={{ margin: "0" }}>Processed</p>;
        }
        if (cell === 2) {
            return <p style={{ margin: "0", color: "red" }}>Pending</p>;
        }
        if (cell === 3) {
            return <p style={{ margin: "0" }}>Payout failed</p>;
        }
        if (cell === 4) {
            return <p style={{ margin: "0" }}>Payout Reversed</p>;
        }
        if (cell === 5) {
            return <p style={{ margin: "0" }}>Pending for approval</p>;
        }
        if (cell === 6) {
            return <p style={{ margin: "0" }}>Rejected by Admin</p>;
        }
    };
    // const dateFormatter = (cell) => {
    //     const date = new Date(cell);
    //     return <Moment format="MMMM Do YYYY ">{date}</Moment>;
    // };
    const debitFormatter = (cell, cellcontent) => {
        if (cell === 2 || cell === 3 || cell === 6 || cell === 7) {
            return (
                <p style={{ color: "red", margin: "0" }}>
                    ₹{cellcontent.amount}
                </p>
            );
        }
    };
    const columns = [
        {
            dataField: "wallet_transactionId",
            text: "Order ID",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "5rem" };
            },
        },
        // {
        //     dataField: "referredByCode",
        //     text: "Referral ID",
        //     sort: false,

        //     headerStyle: (colum, colIndex) => {
        //         return { ...headerstyles, width: "65px" };
        //     },
        // },
        {
            dataField: "firstName",
            text: "Name",
            sort: false,
            // formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "11%" };
            },
        },
        {
            dataField: "notes",
            text: "Request ID",
            sort: false,
            // formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "mobileNumber",
            text: "Mobile Number",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "11%" };
            },
        },

        /*       {
            dataField: "description",
            text: "Decription",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "13%" };
            },
        }, */
        /*    {
            dataField: "walletCommissionPercentage",
            text: "Wallet Commission",
            sort: false,
            formatter: (cell, cellcontent) => {
                return <p style={{ margin: "0" }}>{cell} %</p>;
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "70px" };
            },
        },
        {
            dataField: "settlementType",
            text: "Settlement",
            sort: false,
            formatter: (cell, cellcontent) => {
                return cell == 1 ? "Instant" : (cell = 2 ? "T+1" : "BBPS");
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        }, */
        /*     {
            dataField: "transactionType",
            text: "Credit",
            sort: false,
            formatter: (cell, cellcontent) => {
                if (cell === 1 || cell === 4 || cell === 5 || cell === 8) {
                    if (cellcontent.paymentGateway == "razorpay") {
                        return (
                            <p style={{ color: "green", margin: "0" }}>
                                ₹{cellcontent.amount}
                            </p>
                        );
                    } else {
                    }
                    return (
                        <p style={{ color: "blue", margin: "0" }}>
                            ₹{cellcontent.amount}
                        </p>
                    );
                }
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "70px" };
            },
        }, */

        {
            dataField: "amount",
            text: "Bill Amount",
            sort: false,
            formatter: (cell, cellcontent) => {
                return <p style={{ margin: "0" }}>₹ {cell}</p>;
            },

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "150px" };
            },
        },
        /*     {
            dataField: "payoutStatus",
            text: "Payout Status",
            sort: false,
            formatter: (cell, cellcontent) => {
                return (
                    <p style={{ margin: "0" }}>
                        {" "}
                        {cell == 2 && "pending"}
                        {cell == 0 && "payout not initiated"}
                        {cell == 1 && "Success"}
                        {cell == 3 && "Refund/failed"}
                    </p>
                );
            },
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        }, */
        {
            dataField: "razorpay_TransactionId",
            text: "Transaction ID",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "transactionDateTime",
            text: "Date Time",
            sort: false,
            formatter: (item) => <Moment format="DD-MM-YYYY">{item}</Moment>,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "transactionStatus",
            text: "Status",
            sort: false,
            formatter: StatusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "7rem" };
            },
        },
    ];
    const getTransactions = () => {
        getusers(0);
    };
    const fromchange = () => {
        const current = new Date(fromref.current.value);
        setDates((prev) => {
            return {
                ...prev,
                fromDate: `${current.getFullYear()}-${String(
                    current.getMonth() + 1
                ).padStart(2, "0")}-${String(current.getDate()).padStart(
                    2,
                    "0"
                )}`,
            };
        });
    };
    const tochange = () => {
        const to = new Date(toref.current.value);
        setDates((prev) => {
            return {
                ...prev,
                toDate: `${to.getFullYear()}-${String(
                    to.getMonth() + 1
                ).padStart(2, "0")}-${String(to.getDate()).padStart(2, "0")}`,
            };
        });
    };
    const transactionsHandler = (e) => {
        getTransactions();
    };
    const submithandler = (e) => {
        // setLoading(true);
        e.preventDefault();
        if (searchref.current.value.length == 0) {
            getusers(0);
            return;
        }

        Services.TransactionsById("GET", null, token, searchref.current.value)
            .then((Response) => {
                console.log(Response);
                setLoading(false);
                if (Response.Status === 1) {
                    if (Response?.walletTransaction?.wallet_transactionId) {
                        setData([{ ...Response.walletTransaction }]);
                    } else {
                        setData([]);
                    }
                    setNext(true);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const downloadHandler = (e) => {
        console.log("sasd");
        let downloadData = {};
        if (fromref.current.value.length > 0) {
            const from = new Date(fromref.current.value);
            downloadData = {
                ...downloadData,
                fromDate: `${from.getFullYear()}-${String(
                    from.getMonth() + 1
                ).padStart(2, "0")}-${String(from.getDate()).padStart(2, "0")}`,
            };
        }
        if (toref.current.value.length > 0) {
            const to = new Date(toref.current.value);
            downloadData = {
                ...downloadData,
                toDate: `${to.getFullYear()}-${String(
                    to.getMonth() + 1
                ).padStart(2, "0")}-${String(to.getDate()).padStart(2, "0")}`,
            };
        }

        Services.TransactionsDownload(
            "POST",
            JSON.stringify(downloadData),
            token
        )
            .then((Response) => {
                console.log(Response);
                setLoading(false);
                if (Response.Status === 1) {
                    if (Response.filename) {
                        let url = Response.filename;
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = url.split("/").pop();
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const clearHandler = (e) => {
        e.preventDefault();
        if (searchref.current.value.length > 0) {
            getusers(0);
            searchref.current.value = "";
        }
    };
    console.log(offset);
    return (
        <>
            {model && (
                <TransactionDetails
                    setmodal={setModel}
                    transactionDetails={transactionDetails}
                />
            )}
            <Layout isactive={"BBPS Transaction"}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={tableClasses["table"]}>
                        <h3 className={tableClasses["heading"]}>
                            Transactions
                        </h3>
                        <div className={tableClasses["search-download"]}>
                            <form
                                // onReset={clearHandler}
                                className={tableClasses["search-form"]}
                                onSubmit={submithandler}>
                                <div>
                                    <input
                                        type={"text"}
                                        ref={searchref}
                                        placeholder="Search by Transaction Id"
                                    />
                                    <BiSearchAlt2
                                        className={tableClasses["search-icon"]}
                                        size={25}
                                        color={"#2e3346"}
                                    />
                                </div>
                                <button type="submit">Search</button>
                                <button
                                    onClick={clearHandler}
                                    style={{
                                        marginLeft: "0.1rem",
                                        background: "red",
                                    }}
                                    type="button">
                                    Clear
                                </button>
                            </form>
                            {/*      <button
                                className={tableClasses["download"]}
                                onClick={downloadHandler}>
                                <BsDownload />
                                Download
                            </button> */}
                        </div>
                        <div className={tableClasses["dateinputs"]}>
                            <input
                                type={"date"}
                                ref={fromref}
                                onChange={fromchange}
                            />
                            <input
                                onChange={tochange}
                                type={"date"}
                                ref={toref}
                                max={new Date().toISOString().split("T")[0]}
                            />
                            <button onClick={transactionsHandler}>Go</button>
                        </div>
                        <Table
                            data={data}
                            columns={columns}
                            getdata={getusers}
                            getoffset={offset}
                            max={max}
                            setNext={setNext}
                            next={next}
                            prev={prev}
                            setprev={setprev}
                            prevoffset={prevoffset}
                            setPrevOffset={setPrevOffset}
                            rowevents={row}
                        />
                    </div>
                )}
            </Layout>
        </>
    );
}

export default User;
