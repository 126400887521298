import React, { useEffect, useState, useRef } from "react";
import Layout from "Components/utilities/Layout";
import tableClasses from "Styles/mainCss.module.css";
import Table from "Components/utilities/table";
import Loader from "Components/utilities/loader";
import { Services } from "Services";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
function User() {
    const [loading, setLoading] = useState(true);
    const searchref = useRef();
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    let firstTime;
    const [prevoffset, setPrevOffset] = useState();
    const [next, setNext] = useState(true);
    const [downloadload, setdownloadload] = useState(false);
    const [prev, setprev] = useState(true);
    const [offset, setoffset] = useState(0);
    const [max, setMax] = useState();
    const headerstyles = {
        color: "#1a1717",
        backgroundColor: "white",
        fontWeight: 600,
        fontSize: "0.9rem",
        width: "fit-content",
        textAlign: "center",
    };
    useEffect(() => {
        // eslint-disable-next-line
        firstTime = true;
    }, []);
    const getusers = (offset) => {
        Services.kycPendingUsers("GET", null, token, offset)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.users);
                    if (firstTime) {
                        console.log("11");
                        firstTime = false;
                        setMax(Response.offset);
                    }

                    if (Response.offset !== -1) {
                        setoffset(Response.offset);
                        setNext(false);
                    } else {
                        setNext(true);
                    }
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    useEffect(() => {
        getusers(0);
        // eslint-disable-next-line
    }, []);
    const dateFormatter = (cell) => {
        const date = new Date(cell);
        return <Moment format="MMMM Do YYYY  hh:mm a ">{date}</Moment>;
    };
    const kycChangeHandler = (e) => {
        let body = JSON.stringify({
            isKYCverified: Number(e.target.value),
        });
        Services.kycUpdate(
            "PUT",
            body,
            token,
            e.currentTarget.getAttribute("id")
        )
            .then((Response) => {
                console.log(Response);
                // offset
                if (Response.Status === 1) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    toast.success(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const statusFormatter = (cell, cellContent) => {
        return (
            <select
                onChange={kycChangeHandler}
                id={cellContent.userId}
                defaultValue={0}>
                <option value="0">Pending</option>
                <option value="1">Approve</option>
            </select>
        );
    };
    const download = (offset) => {
        setdownloadload(true);
        // return;
        Services.downloadpendingcsv("GET", null, token, offset)
            .then((Response) => {
                console.log(Response);
                // offset
                setdownloadload(false);

                if (Response.Status === 1) {
                    window.open(Response.FileName, "_blank");
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const exportHandler = (e) => {
        download(offset);
    };
    const columns = [
        {
            dataField: "firstName",
            text: "Name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "emailId",
            text: "Email",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "mobileNumber",
            text: "Mobile Number",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "registrationDate",
            text: "Registration date",
            sort: false,
            formatter: dateFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, width: "20%" };
            },
        },
        /*  {
            dataField: "walletCommissionPercentage",
            text: "Wallet Commission Percentage",
            sort: false,
            formatter: (cell) => (
                <p style={{ textAlign: "center" }}>{cell}% </p>
            ),
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "walletAmount",
            text: "Wallet Balance",
            sort: false,
            formatter: (cell) => (
                <p style={{ textAlign: "center" }}>₹{cell} </p>
            ),
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
 */
        {
            dataField: "Status",
            text: "Status",
            sort: false,
            formatter: statusFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
    ];
    const submithandler = (e) => {
        // setLoading(true);
        e.preventDefault();
        if (searchref.current.value.length == 0) {
            getusers(0);
            return;
        }
        console.log("sss");
        let body = {};
        if (isNaN(searchref.current.value)) {
            body = { name: searchref.current.value, isKYCverified: 0 };
        } else {
            body = { mobileNumber: searchref.current.value, isKYCverified: 0 };
        }
        Services.SearchUserBynameOrMobile("POST", JSON.stringify(body), token)
            .then((Response) => {
                console.log(Response);
                setLoading(false);
                if (Response.Status === 1) {
                    setData(Response.Users);
                    setNext(true);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const clearHandler = (e) => {
        e.preventDefault();
        if (searchref.current.value.length > 0) {
            getusers(0);
            searchref.current.value = "";
        }
    };
    return (
        <Layout isactive={"Kyc"}>
            {loading ? (
                <Loader />
            ) : (
                <div className={tableClasses["table"]}>
                    <div
                        className={tableClasses["heading-export"]}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <h3 className={tableClasses["heading"]}>
                            KYC Pending Users
                        </h3>
                        <button
                            style={{ margin: "0.5rem" }}
                            onClick={exportHandler}
                            disabled={downloadload}>
                            {downloadload ? "loading..." : "export"}
                        </button>
                    </div>
                    <form
                        className={tableClasses["search-form"]}
                        onSubmit={submithandler}>
                        <div>
                            <input
                                type={"text"}
                                ref={searchref}
                                placeholder="Search by name or number"
                            />
                            <BiSearchAlt2
                                className={tableClasses["search-icon"]}
                                size={25}
                                color={"#2e3346"}
                            />
                        </div>
                        <button type="submit">Search</button>
                        <button
                            onClick={clearHandler}
                            style={{
                                marginLeft: "0.1rem",
                                background: "red",
                            }}
                            type="button">
                            Clear
                        </button>
                    </form>
                    <Table
                        data={data}
                        columns={columns}
                        getdata={getusers}
                        getoffset={offset}
                        max={max}
                        setNext={setNext}
                        next={next}
                        prev={prev}
                        setprev={setprev}
                        prevoffset={prevoffset}
                        setPrevOffset={setPrevOffset}
                    />
                </div>
            )}
        </Layout>
    );
}

export default User;
