import React, { useState, useEffect } from "react";
import Layout from "Components/utilities/Layout";
import StyleClasses from "Styles/mainCss.module.css";
import { useForm } from "react-hook-form";
import { Services } from "Services";
import Loader from "Components/utilities/loader";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";

function FetchBill() {
    const [loading, setLoading] = useState(true);
    const [billdetails, setbilldetails] = useState();
    const [secondLoad, setSecondload] = useState(false);
    const [settings, setSettings] = useState({});
    const [billername, setbillername] = useState(
        sessionStorage.getItem("billerName")
    );
    const [amount, setamount] = useState();

    let bill = sessionStorage.getItem("billdetails");
    useEffect(() => {
        setamount(Number(sessionStorage.getItem("amount")) / 100);

        if (bill) {
            setbilldetails(JSON.parse(bill));
            console.log(JSON.parse(bill));
        }
        getSettings();
    }, []);
    const navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
    console.log(billdetails, "22");
    const {
        register,
        handleSubmit,
        watch,
        unregister,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
    const getSettings = () => {
        Services.getSettings("GET", null, token)
            .then((Response) => {
                console.log(Response);
                // offset
                setLoading(false);
                if (Response.Status === 1) {
                    setSettings(Response.Settings);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    const amountChange = (e) => {
        setamount(e.target.value);
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const billerAdhoc = localStorage.getItem("billerAdhoc");
        console.log(billdetails);
        let id = {};
        if (billerAdhoc) {
            id = {
                RequestID: sessionStorage.getItem("RequestID"),
            };
        }
        let additionalInfo = [];
        if (billdetails?.additionalInfo) {
            additionalInfo = Array.isArray(billdetails?.additionalInfo?.info)
                ? [...billdetails?.additionalInfo?.info]
                : [{ ...billdetails?.additionalInfo?.info }];
        }

        let data = {
            billerId: sessionStorage.getItem("billerid"),
            Amount: Number(amount) * 100,
            charges: Math.ceil(
                Number(amount) * (settings?.BBPSChargesPercentage / 100)
            ),
            billerAdhoc: localStorage.getItem("billerAdhoc"),
            ...id,
            mobileNumber: localStorage.getItem("mobilenumber"),
            Params: {
                additionalInfo: {
                    info: additionalInfo.length > 0 ? additionalInfo : 0,
                },
                inputParams: {
                    input: [...billdetails?.inputParams.input],
                },
                billerResponse: { ...billdetails?.billerResponse },

                paymentMethod: {
                    paymentMode: "NEFT",
                    quickPay: "N",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
                paymentInfo: {
                    info: [
                        {
                            infoName: "IFSC",
                            infoValue: "TESTCCAV0001",
                        },
                        {
                            infoName: "AccountNo",
                            infoValue: "PG",
                        },
                    ],
                },
            },
        };

        console.log(data);
        setSecondload(true);
        Services.billerPay("POST", JSON.stringify(data), token)
            .then((response) => {
                setSecondload(false);
                if (response?.Status == 1) {
                    // setModal(false);
                    sessionStorage.setItem(
                        "success",
                        JSON.stringify(response?.BillPayResponse)
                    );
                    navigate(`/dashboard/bill-payments/paybill-success`);
                } else if (response.Status == 0) {
                    alert(response?.Message);
                }
                console.log(response);
            })
            .catch((err) => {
                setSecondload(false);
                console.log(err);
                alert("some thing went wrong try after sometime");
            });
    };

    return (
        <>
            <Layout isactive={"Fetch"}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={StyleClasses["Bill_fetch_container"]}>
                        <form
                            onSubmit={onSubmit}
                            style={{
                                gridTemplateColumns: "1fr 1fr",
                                padding: "1rem",
                                width: "100%",
                            }}>
                            <h3 style={{ gridColumn: "1/-1" }}>Pay Bill</h3>
                            <div className={StyleClasses["form-control"]}>
                                <label htmlFor="billername">Biller Name</label>
                                <input
                                    type="text"
                                    placeholder={billername}
                                    disabled
                                />
                            </div>
                            {(Array.isArray(billdetails?.additionalInfo?.info)
                                ? [...billdetails?.additionalInfo?.info]
                                : [billdetails?.additionalInfo?.info]
                            ).map((item) => {
                                return (
                                    <div
                                        className={
                                            StyleClasses["form-control"]
                                        }>
                                        <label htmlFor={item?.infoName}>
                                            {item?.infoName}
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={item?.infoValue}
                                            disabled
                                        />
                                    </div>
                                );
                            })}
                            {billdetails?.inputParams?.input?.map((item) => {
                                return (
                                    <div
                                        className={
                                            StyleClasses["form-control"]
                                        }>
                                        <label htmlFor={item?.input?.paramName}>
                                            {item?.paramName}
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={item?.paramValue}
                                            disabled
                                        />
                                    </div>
                                );
                            })}
                            <div className={StyleClasses["form-control"]}>
                                <label htmlFor="due date">Due Date</label>
                                <input
                                    type="text"
                                    placeholder={
                                        billdetails?.billerResponse?.dueDate
                                    }
                                    disabled
                                />
                            </div>
                            <div className={StyleClasses["form-control"]}>
                                <label htmlFor="billAmount">bill Amount</label>
                                <input
                                    type="number"
                                    value={amount}
                                    onChange={amountChange}
                                />
                            </div>
                            <button
                                disabled={secondLoad}
                                style={{ gridColumn: "1/-1" }}>
                                {" "}
                                {secondLoad ? "loading" : "Pay"}
                            </button>
                        </form>
                    </div>
                )}
            </Layout>
        </>
    );
}

export default FetchBill;
