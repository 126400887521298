import React, { useEffect, useState } from "react";
import LoginClasses from "Styles/login.module.css";
import { useForm } from "react-hook-form";
import { AiOutlineUser } from "react-icons/ai";
import { RiLock2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Services } from "Services";
import { toast } from "react-toastify";
function Login() {
    const [submitEnabler, setSubmitEnable] = useState(true);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const formdata = watch();
    useEffect(() => {
        if (formdata?.emailId?.length > 0 && formdata?.password?.length > 0) {
            setSubmitEnable(false);
        } else {
            setSubmitEnable(true);
        }
    }, [formdata]);
    const onSubmit = (data) => {
        Services.login("POST", JSON.stringify({ ...data }))
            .then((response) => {
                if (response.Status === 1) {
                    Navigate("/dashboard/user");
                    localStorage.setItem("token", response.Token);
                    localStorage.setItem(
                        "userdetails",
                        JSON.stringify(response.adminDetails)
                    );
                } else {
                    toast.error(`${response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                console.log(response);
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
            });
    };
    return (
        <div className={LoginClasses["login-wrapper"]}>
            <div className={LoginClasses["left-div"]}></div>
            <div className={LoginClasses["right-div"]}>
                <h3>
                    Welcome to <span>Paygen</span>
                </h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={LoginClasses["form-data"]}>
                        <label htmlFor="Username">Username</label>
                        <div className={LoginClasses["input"]}>
                            <AiOutlineUser className={LoginClasses["icon"]} />
                            <input
                                placeholder="Enter Username"
                                type={"text"}
                                {...register("emailId", {
                                    required: "Username is Required",
                                })}
                            />
                            {errors?.emailId && (
                                <p className={LoginClasses.error}>
                                    {errors?.emailId?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={LoginClasses["form-data"]}>
                        <label htmlFor="Password">Password</label>
                        <div className={LoginClasses["input"]}>
                            <RiLock2Line className={LoginClasses["icon"]} />
                            <input
                                placeholder="Enter Password"
                                type="password"
                                {...register("password", {
                                    required: "Password is Required",
                                })}
                            />
                            {errors?.password && (
                                <p className={LoginClasses.error}>
                                    {errors?.password?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <input
                        type={"submit"}
                        value={"Login"}
                        disabled={submitEnabler}
                    />
                </form>
            </div>
        </div>
    );
}

export default Login;
