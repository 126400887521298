import React, { useState, useEffect } from "react";
import Layout from "Components/utilities/Layout";
import Styles from "Styles/mainCss.module.css";
function BillSuccess() {
    const billername = sessionStorage.getItem("billerName");
    const billerid = sessionStorage.getItem("billerid");
    const [successdetails, setsuccessdetails] = useState();
    const success = sessionStorage.getItem("success");

    useEffect(() => {
        if (success) {
            setsuccessdetails(JSON.parse(success));
            console.log(JSON.parse(success));
        }
    }, []);
    return (
        <Layout isactive={"Fetch"}>
            <div className={Styles["Success_Container"]}>
                <div className={Styles["Success_payment"]}>
                    <h3>Payment Success</h3>
                    <div className={Styles["success-details"]}>
                        <div className={Styles["success-detail"]}>
                            <p>Biller Name</p>
                            <p>:</p>
                            <p>{billername}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Biller Id</p>
                            <p>:</p>
                            <p>{billerid}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Transaction ID</p>
                            <p>:</p>
                            <p>{successdetails?.txnRefId}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Customer Name</p>
                            <p>:</p>
                            <p>{successdetails?.RespCustomerName}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Mobile Number</p>
                            <p>:</p>
                            <p>{localStorage.getItem("mobilenumber")}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Bill Date</p>
                            <p>:</p>
                            <p>{successdetails?.RespBillDate}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Bill Number</p>
                            <p>:</p>
                            <p>{successdetails?.RespBillNumber}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Due Date</p>
                            <p>:</p>
                            <p>{successdetails?.RespDueDate}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Bill amount</p>
                            <p>:</p>
                            <p>₹ {Number(successdetails?.RespAmount) / 100}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>CCF</p>
                            <p>:</p>
                            <p>₹ {successdetails?.CustConvFee}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Total Amount</p>
                            <p>:</p>
                            <p>
                                ₹{" "}
                                {Number(successdetails?.RespAmount) / 100 +
                                    Number(successdetails?.CustConvFee)}{" "}
                            </p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Transaction Date & time</p>
                            <p>:</p>
                            <p>{successdetails?.TransactionDateTime}</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Init Channel</p>
                            <p>:</p>
                            <p>AGT</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Payment Mode</p>
                            <p>:</p>
                            <p>WALLET</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Transaction Status</p>
                            <p>:</p>
                            <p>Successful</p>
                        </div>
                        <div className={Styles["success-detail"]}>
                            <p>Approval</p>
                            <p>:</p>
                            <p>{successdetails?.approvalRefNumber}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default BillSuccess;
