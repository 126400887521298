import "./App.css";

import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Components/Login/login";
import User from "./Components/User/user";
import KycPending from "./Components/kycPending/Pending";
import Verified from "./Components/Verified/Verified";
import Transaction from "./Components/Transaction/transaction";
import WalletSummary from "./Components/walletSummary/sumary";
import Referral from "./Components/Referral/referral";
import Settings from "./Components/settings/settings";
import AdminUsers from "./Components/admin/adminusers";
import UserDetails from "./Components/Verified/Userdetails";
import UserWallet from "./Components/walletSummary/User-wallet-details";
import BlockedUsers from "./Components/BlockedAccounts/BlockedUsers";
import PayoutContacts from "./Components/Verified/PayoutContacts";
import Dashboard from "Components/Dashboard/dashboard";
import ReferralUsers from "Components/Verified/referralUsers";
import RefferalCommision from "Components/ReferralCommisions/referral";
import PendingTransactions from "Components/pendingTransactions/pendingTransactions";
import T1transactions from "./Components/T1Transactions/T1"
import KycSemiVerified from "./Components/kycsemiVerified/Pending"
import KyvsemiverifiedDetails from "./Components/kycsemiVerified/Userdetails"
import Approval from "./Components/Approval/Verified"
import PayoutContactsVerify from "./Components/payoutContacts/payout"
import BBPSTransactions from './Components/BBPS/transaction'
import FetchBill from './Components/BBPS/FetchBill'
import PayBill from './Components/BBPS/BillPayAmount'
import BillSuccess from "Components/BBPS/BillSuccess";
function App() {

    return (
        <>
            <ToastContainer />
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/dashboard/user" element={<User />} />
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route
                    exact
                    path="/dashboard/kyc-pending-users"
                    element={<KycPending />}
                />
                <Route
                    exact
                    path="/dashboard/verified-users"
                    element={<Verified />}
                />
                <Route
                    exact
                    path="/dashboard/transaction-history"
                    element={<Transaction />}
                />
                <Route
                    exact
                    path="/dashboard/wallet-summary"
                    element={<WalletSummary />}
                />
                <Route
                    exact
                    path="/dashboard/referral"
                    element={<Referral />}
                />
                <Route
                    exact
                    path="/dashboard/settings"
                    element={<Settings />}
                />
                <Route
                    exact
                    path="/dashboard/admin-users"
                    element={<AdminUsers />}
                />
                <Route
                    exact
                    path="/dashboard/verified-users/:id"
                    element={<UserDetails />}
                />
                <Route
                    exact
                    path="/dashboard/Kycsemiverified/:id"
                    element={<KyvsemiverifiedDetails />}
                />
                <Route
                    exact
                    path="/dashboard/wallet-summary/:id"
                    element={<UserWallet />}
                />
                <Route
                    exact
                    path="dashboard/blockedAccounts"
                    element={<BlockedUsers />}
                />
                <Route
                    exact
                    path="payoutContacts/:id"
                    element={<PayoutContacts />}
                />
                <Route
                    exact
                    path="ReferralUsers/:userid"
                    element={<ReferralUsers />}
                />
                <Route
                    exact
                    path="/referralCommission"
                    element={<RefferalCommision />}
                />
                <Route exact path="/dashboard/T1-transactions" element={<T1transactions />} />
                <Route
                    exact
                    path="/dashboard/pending-transactions"
                    element={<PendingTransactions />}
                />
                <Route
                    exact
                    path="/dashboard/Kycsemiverified"
                    element={<KycSemiVerified />}
                />
                <Route
                    exact
                    path="/dashboard/Approval"
                    element={<Approval />}
                />
                <Route
                    exact
                    path="/dashboard/Payout-contacts"
                    element={<PayoutContactsVerify />}
                />
                <Route
                    exact
                    path="/dashboard/bbps-transactions"
                    element={<BBPSTransactions />}
                />
                <Route
                    exact
                    path="/dashboard/fetch-bill"
                    element={<FetchBill />}
                />
                <Route
                    exact
                    path="/dashboard/paybill-amount"
                    element={<PayBill />}
                />
                <Route
                    exact
                    path="/dashboard/bill-payments/paybill-success"
                    element={<BillSuccess />}
                />
            </Routes>
        </>
    );
}

export default App;
