import React, { useState, useEffect } from "react";
import Layout from "Components/utilities/Layout";
import StyleClasses from "Styles/mainCss.module.css";
import { useForm } from "react-hook-form";
import { Services } from "Services";
import Loader from "Components/utilities/loader";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";

function FetchBill() {
    const [Billers, setBillers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [secondLoad, setSecondload] = useState(false);
    const navigate = useNavigate();
    const [billerInfo, setBillerInfo] = useState([]);
    const [submitEnabler, setsubmitEnabler] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
    const {
        register,
        handleSubmit,
        watch,
        unregister,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
    const allFields = watch();
    useEffect(() => {
        Services.fetchBill("GET", null, token)
            .then((resp) => {
                setLoading(false);
                if (resp.Status == 1) {
                    setBillers(resp?.BillersList);
                } else {
                    toast.error(`${Response.Message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                alert("something went wrong please try later");
                console.log(err);
                setLoading(false);
            });
    }, []);

    const unregisterAllExcept = (fieldToKeep) => {
        // Loop through all registered fields
        setBillerInfo([]);
        Object.keys(allFields).forEach((field) => {
            if (field !== fieldToKeep) {
                unregister(field); // Unregister everything except the specified field
            }
        });
        console.log(watch());
    };
    const selectBillerHandler = (e) => {
        let body = { billerId: e.target.value };
        reset({ Biller: getValues("Biller") });
        unregisterAllExcept("Biller");
        setSecondload(true);
        setTimeout(() => {
            Services.billerInfo("POST", JSON.stringify(body), token)
                .then((response) => {
                    setSecondload(false);
                    if (response.Status == 1) {
                        // setBillers(resp?.BillersList);
                        setsubmitEnabler(true);
                        setBillerInfo(response?.BillerInfo);
                        localStorage.setItem(
                            "billerAdhoc",
                            response?.BillerInfo?.billerAdhoc
                        );
                        sessionStorage.setItem(
                            "billerName",
                            response?.BillerInfo?.billerName
                        );
                    } else {
                        toast.error(`${Response.Message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    setSecondload(false);
                    alert("something went wrong please try later");
                    console.log(err);
                    setLoading(false);
                });
        }, 500);
    };

    const onSubmit = (data) => {
        if (!submitEnabler) {
            setsubmitEnabler(true);
        } else {
            let datacopy = { ...data };
            delete datacopy?.Biller;
            console.log(data);
            const result = Object.entries(datacopy).map((e) => ({
                [e[0]]: e[1],
            }));

            const inputresult = result?.map((item) => {
                return {
                    paramName: Object?.keys(item)[0],
                    paramValue: Object?.values(item)[0],
                };
            });
            function getFormattedMobileNumber(data) {
                const inputArray = data.Params.inputParams.input;

                for (let item of inputArray) {
                    if (item.paramName.toLowerCase().includes("mobile")) {
                        return item.paramValue.toLowerCase().trim();
                    }
                }

                return null; // Return null if no matching paramName is found
            }
            const body = {
                billerId: data?.Biller,
                Params: { inputParams: { input: [...inputresult] } },
                mobileNumber: getFormattedMobileNumber({
                    Params: { inputParams: { input: [...inputresult] } },
                }),
            };
            console.log(body);
            setSecondload(true);
            localStorage.setItem("mobilenumber", body.mobileNumber);
            Services.billerFetch("POST", JSON.stringify(body), token)
                .then((response) => {
                    setSecondload(false);
                    console.log(response);
                    if (response.Status == 1) {
                        console.log("detail", response);
                        sessionStorage.setItem(
                            "billdetails",
                            JSON.stringify(response.BillDetails)
                        );
                        sessionStorage.setItem("RequestID", response.RequestID);
                        sessionStorage.setItem(
                            "amount",
                            response.BillDetails?.billerResponse?.billAmount
                        );
                        sessionStorage.setItem("billerid", data?.Biller);
                        navigate(`/dashboard/paybill-amount`);
                    } else {
                        toast.error(`${response.Message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    setSecondload(false);
                    alert("something went wrong please try later");
                    console.log(err);
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Layout isactive={"Fetch"}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={StyleClasses["Bill_fetch_container"]}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h3>Fetch Bill</h3>
                            <div className={StyleClasses["form-control"]}>
                                <select
                                    className={
                                        errors.Biller
                                            ? StyleClasses["error_input"]
                                            : ""
                                    }
                                    defaultValue={""}
                                    {...register("Biller", {
                                        required: "Please select Biller",
                                        onChange: (e) => {
                                            selectBillerHandler(e);
                                        },
                                    })}>
                                    <option value="" disabled>
                                        {" "}
                                        Select Biller
                                    </option>
                                    {Billers.map((item) => (
                                        <option value={item?.blr_id}>
                                            {item?.blr_name}
                                        </option>
                                    ))}
                                </select>
                                {errors.Biller && <p>This is required Field</p>}
                            </div>
                            {submitEnabler && (
                                <>
                                    {billerInfo?.billerInputParams?.paramInfo?.map(
                                        (data) => {
                                            const name = data?.paramName;
                                            const boolValue =
                                                data?.isOptional == "true";
                                            // console.log(name);
                                            return (
                                                <div
                                                    className={
                                                        StyleClasses[
                                                            "form-control"
                                                        ]
                                                    }>
                                                    <input
                                                        className={
                                                            errors[`${name}`]
                                                                ? StyleClasses[
                                                                      "error_input"
                                                                  ]
                                                                : ""
                                                        }
                                                        data-regex={data?.regEx}
                                                        minLength={
                                                            data?.minLength
                                                        }
                                                        maxLength={
                                                            data?.maxLength
                                                        }
                                                        data-name={
                                                            data?.paramName
                                                        }
                                                        id="billerinput"
                                                        placeholder={name}
                                                        type={"text"}
                                                        pattern={data?.regEx}
                                                        /* disabled={
                                                            data.paramName ===
                                                            "Registered Mobile Number"
                                                        } */
                                                        {...register(
                                                            `${name}`,
                                                            {
                                                                required:
                                                                    !boolValue,
                                                                pattern: {
                                                                    value: data?.regEx,
                                                                    message: `invalid input`,
                                                                },
                                                            }
                                                        )}
                                                    />
                                                    {errors[`${name}`] && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                            }}>
                                                            Enter a Valid{" "}
                                                            {data?.paramName}
                                                        </p>
                                                    )}
                                                </div>
                                            );
                                        }
                                    )}
                                </>
                            )}
                            {submitEnabler && !secondLoad && (
                                <button> Submit</button>
                            )}
                            {submitEnabler && secondLoad && (
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={80}
                                />
                            )}
                        </form>
                    </div>
                )}
            </Layout>
        </>
    );
}

export default FetchBill;
